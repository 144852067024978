import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { FormikProvider, useFormik } from "formik";
import TemplateImageUser from "../../components/TemplateImageUser";
import WrapFormItemLicensee from "../../components/WrapFormItemLicensee";
import * as yup from "yup";
import WrapButtonSubmitTrailer from "../../components/WrapButtonSubmitTrailer";
import WrapHeaderSubPage from "../../components/WrapHeaderSubPage";
import { useLoading } from "../../hooks/useLoading";
import axiosInstance from "../../helpers/axios";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonActionHeader from "../../components/ButtonActionHeader";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import FormikControl, { CONTROL_TYPE } from "../../components/Formik/FormikControl";
import { USER_ROLES, common } from "../../constants/common";
import { useRedirectToHomeIfUnauthorized } from "../../hooks/useAuth";

const AddOrEditAdminUser = () => {
  useRedirectToHomeIfUnauthorized([USER_ROLES.ADMIN_OWNER]);
  const [image, setImage] = useState({
    url: "",
    file: "",
  });

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    role: common?.USER_ROLES?.[2]?.value ?? "",
  });
  const location = useLocation();
  let history = useHistory();
  const [showLoading, hideLoading] = useLoading();

  useEffect(() => {
    if (location.state?.detail) {
      setImage({ url: location.state?.detail?.photos?.data, file: "" });
      setUserData(location.state.detail);
    }
  }, [location.state?.detail]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userData,
    validationSchema: yup.object({
      name: yup.string().trim().required(" "),
      email: yup.string().trim().required(" ").email(" "),
      role: yup.string().trim(),
    }),
    onSubmit: (values) => {
      console.log("SUBMITTING");
      handleAddUser(values);
    },
  });

  const handleAddUser = async (user) => {
    showLoading();
    try {
      const formData = new FormData();
      formData.append("name", user.name);
      formData.append("email", user.email);
      formData.append("role", user.role);

      if (image.file) {
        formData.append("photos", image.file);
      }

      if (location.state?.detail) {
        const res = await axiosInstance.put(
          "v1/admin/user/" + location.state?.detail._id,
          formData
        );
        toast.success(res?.data?.message);
      } else {
        const res = await axiosInstance.post("v1/admin/user/", formData);
        toast.success(res?.data?.message);
      }

      hideLoading();
      history.push("/admin");
    } catch (err) {
      hideLoading();
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <Box height="100%" paddingBottom="85px">
      <WrapHeaderSubPage nameHeader={location.state?.detail ? "Edit User" : "New User"} />
      <Breadcrumbs />
      <Box display="flex">
        <TemplateImageUser onAddImage={setImage} dataImage={image} />
        <WrapFormItemLicensee formik={formik}>
          <FormikProvider value={formik}>
            <FormikControl name="name" control={CONTROL_TYPE.INPUT} label="Name" />
            <FormikControl name="email" control={CONTROL_TYPE.INPUT} label="Email" />
            <FormikControl
              name="role"
              control={CONTROL_TYPE.SELECT}
              options={common?.USER_ROLES}
              label="Role"
            />
            <WrapButtonSubmitTrailer>
              <ButtonActionHeader type="submit" width="233px">
                {location.state?.detail ? "Update User" : "Add User"}
              </ButtonActionHeader>
            </WrapButtonSubmitTrailer>
          </FormikProvider>
        </WrapFormItemLicensee>
      </Box>
    </Box>
  );
};

export default AddOrEditAdminUser;
